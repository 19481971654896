const SvgPrescriptionCard = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <path
      d="M46.1887 31.7567C48.9372 32.0295 50.9439 34.4791 50.6703 37.2275L46.3293 80.8412C46.0551 83.5958 43.595 85.6034 40.8413 85.3196L28.9561 84.0948C26.2157 83.8124 24.2201 81.3665 24.4934 78.6252L28.8366 35.0571C29.1104 32.3102 31.5586 30.3049 34.3057 30.5775L46.1887 31.7567Z"
      fill="#E4E9ED"
    />
    <path
      d="M31.345 30.275L93.8736 36.456C94.9728 36.5647 95.7758 37.5439 95.6672 38.6431L90.7828 88.0548C90.6741 89.154 89.6949 89.957 88.5957 89.8484L26.0671 83.6673C24.9679 83.5587 24.1649 82.5795 24.2736 81.4803L29.158 32.0686C29.2667 30.9693 30.2458 30.1663 31.345 30.275Z"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <path
      d="M62.1182 49.3936L72.2053 50.3907"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.999 58L81.1734 59.9943"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.499 71.334L79.6734 73.3282"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.165 64.667L80.3394 66.6612"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.9984 43.583L52.9984 44.9997L52.6657 49.4163H50.749V51.7497C52.0548 52.2775 54.4995 54.233 53.8324 57.833C53.1652 61.433 52.1099 70.0552 51.6657 73.9163L34.499 72.083L36.0817 54.3332C36.3595 53.3054 37.4984 51.1665 39.8317 50.8332L40.0817 48.2498L38.3317 47.8332L38.9984 43.583Z"
      fill="#F6F9FC"
    />
    <path
      d="M40.0974 56.6415C40.2151 55.4507 41.2759 54.5808 42.4667 54.6985L53.8909 55.8278L52.0802 74.1455L38.4998 72.803L40.0974 56.6415Z"
      fill="#88B04B"
    />
    <rect
      x={48.5225}
      y={55.2969}
      width={4.76042}
      height={18.7243}
      transform="rotate(5.64541 48.5225 55.2969)"
      fill="#CAE2BB"
    />
    <path
      d="M50.5253 51.7442L50.7653 49.3163L52.1851 49.4566C52.4599 49.4838 52.7046 49.2831 52.7318 49.0083L53.0878 45.4074C53.1149 45.1326 52.9142 44.8878 52.6394 44.8607L39.2215 43.5343C38.9467 43.5071 38.7019 43.7079 38.6747 43.9827L38.3188 47.5835C38.2916 47.8583 38.4924 48.1031 38.7672 48.1303L40.1869 48.2706L39.9434 50.7343C38.8567 50.9807 37.8871 51.5211 37.1648 52.2831C36.4424 53.0452 36.0019 53.9924 35.9023 54.9976L34.3187 71.0175C34.2373 71.8419 34.8395 72.5763 35.6639 72.6578L50.5419 74.1285C51.3663 74.21 52.1007 73.6077 52.1822 72.7833L53.7655 56.7657C53.8668 55.7415 53.6091 54.7075 53.0284 53.8075C52.4476 52.9076 51.5727 52.1864 50.5253 51.7442Z"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0807 49.3329L40.3428 48.2715"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPrescriptionCard;
