const SvgBell = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 -1.32224e-05 93.1371 -1.32224e-05 60C-1.32224e-05 26.8629 26.8629 6.15765e-06 60 6.15765e-06C93.1371 6.15765e-06 120 26.8629 120 60Z"
      fill="#FAF8F2"
    />
    <path
      d="M63.583 41.834V75.2507H81.9997C80.0552 73.1395 76.283 66.4673 76.7497 56.6673C76.3608 52.4729 73.183 43.634 63.583 41.834Z"
      fill="#FCE7B1"
    />
    <path
      d="M43.3065 56.6114C43.6083 66.3198 39.7766 73.1227 37.8231 75.3106C35.4557 76.5942 34.8445 78.9571 34.8348 79.9781C34.742 84.2489 38.2776 85.55 40.0571 85.6667H80.4429C83.4892 84.6456 84.9108 83.2454 84.9979 79.9781C85.0675 77.3643 83.112 75.7774 82.1256 75.3106C77.2979 70.083 76.5745 60.6663 76.8163 56.6114C76.265 51.6036 72.1162 41.5704 59.9308 41.5004C47.7455 41.4303 43.7707 51.5452 43.3065 56.6114Z"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <line
      x1={38.1663}
      y1={75.1667}
      x2={51.6663}
      y2={75.1667}
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
    <line
      x1={56.8333}
      y1={75.1667}
      x2={81.6667}
      y2={75.1667}
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
    <mask id="path-6-inside-1_485_4348" fill="white">
      <path d="M68.6663 85.25C68.6663 86.3991 68.4422 87.5369 68.0066 88.5985C67.5711 89.6601 66.9327 90.6247 66.1279 91.4372C65.3232 92.2497 64.3678 92.8942 63.3163 93.3339C62.2648 93.7737 61.1378 94 59.9997 94C58.8616 94 57.7346 93.7737 56.6831 93.3339C55.6316 92.8942 54.6762 92.2497 53.8714 91.4372C53.0666 90.6247 52.4283 89.6601 51.9927 88.5985C51.5572 87.5369 51.333 86.3991 51.333 85.25L59.9997 85.25H68.6663Z" />
    </mask>
    <path
      d="M68.6663 85.25C68.6663 86.3991 68.4422 87.5369 68.0066 88.5985C67.5711 89.6601 66.9327 90.6247 66.1279 91.4372C65.3232 92.2497 64.3678 92.8942 63.3163 93.3339C62.2648 93.7737 61.1378 94 59.9997 94C58.8616 94 57.7346 93.7737 56.6831 93.3339C55.6316 92.8942 54.6762 92.2497 53.8714 91.4372C53.0666 90.6247 52.4283 89.6601 51.9927 88.5985C51.5572 87.5369 51.333 86.3991 51.333 85.25L59.9997 85.25H68.6663Z"
      fill="#FCE7B1"
      stroke="#36485C"
      strokeWidth={3.33333}
      mask="url(#path-6-inside-1_485_4348)"
    />
    <path
      d="M56.1551 42.0007C55.7273 40.0261 55.9276 36.0284 60.151 35.834C61.9206 35.9656 65.0921 37.3832 63.6221 42.0007"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <path
      d="M76.903 29.0842C81.915 30.0776 91.7275 35.1208 90.8812 47.3462"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
    <path
      d="M43.1517 29.0842C38.1397 30.0776 28.3272 35.1208 29.1734 47.3462"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
    <path
      d="M75.6666 35.3981C78.8845 36.2844 85.22 40.087 84.8186 48.2062"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
    <path
      d="M44.3891 35.3981C41.1712 36.2844 34.8357 40.087 35.2371 48.2062"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgBell;
