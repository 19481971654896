const SvgSupplements = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <path
      d="M73.6657 33.5V86.5C70.6101 85.52 64.499 81.9708 64.499 75.6139V43.8298C64.5627 41.4195 66.4851 35.9792 73.6657 33.5Z"
      fill="#C0D8F0"
    />
    <circle cx={43.4993} cy={45.8333} r={12.5} stroke="#36485C" strokeWidth={1.66667} />
    <circle cx={43.4993} cy={74.0003} r={12.5} stroke="#36485C" strokeWidth={1.66667} />
    <line
      x1={34.5855}
      y1={36.742}
      x2={52.5855}
      y2={54.5753}
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <line
      x1={34.5855}
      y1={64.908}
      x2={52.5855}
      y2={82.7413}
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <line
      x1={65.166}
      y1={59.1667}
      x2={88.4994}
      y2={59.1667}
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <rect
      x={64.4993}
      y={33.3333}
      width={24.6667}
      height={53.1667}
      rx={12.3333}
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <path
      d="M77.499 39.6737C79.6134 39.5569 83.7339 40.7251 83.3007 46.3327"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgSupplements;
