const SvgPeople = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <mask
      id="mask0_485_4202"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={2}
      width={118}
      height={119}
    >
      <path
        d="M118.742 61.1673C118.742 93.6601 92.3849 120.001 59.8716 120.001C27.3582 120.001 1.00098 93.6601 1.00098 61.1673C1.00098 40.752 17.5885 2.33398 59.8716 2.33398C92.3938 2.33398 118.742 28.6746 118.742 61.1673Z"
        fill="#F6F9FC"
      />
    </mask>
    <g mask="url(#mask0_485_4202)">
      <path
        d="M86.0348 55.9093C87.7362 56.0416 89.472 56.1064 91.2421 56.1037H92.2304C93.0889 54.1214 93.5307 51.984 93.5283 49.824C93.5273 49.2329 93.4942 48.6422 93.4291 48.0547C92.7126 47.8844 91.9787 47.7978 91.2421 47.7969C85.6181 47.7969 81.0616 52.6683 81.0616 59.9636C81.0576 61.3329 81.1718 62.6999 81.403 64.0496V64.0218C83.5746 61.7274 85.1631 58.9452 86.0348 55.9093Z"
        fill="#C0D8F0"
      />
      <path
        d="M44.9557 54.4613C44.9557 55.2547 44.9425 56.0481 44.916 56.8415C47.3826 57.2137 49.9022 56.9985 52.2699 56.2135C54.6375 55.4285 56.7863 54.0958 58.5413 52.324C60.2963 50.5521 61.6079 48.3911 62.3693 46.0169C63.1308 43.6426 63.3207 41.1222 62.9234 38.6608C61.8409 38.381 60.7274 38.2384 59.6093 38.2363C52.1277 38.2403 44.9557 44.7303 44.9557 54.4613Z"
        fill="#C5CFD9"
      />
      <path
        d="M19.5534 59.9719C22.7021 59.9757 25.7806 59.0419 28.3961 57.2897C31.0116 55.5374 33.0457 53.0462 34.2388 50.1338C32.5278 48.6099 30.315 47.7696 28.0233 47.7735C22.4151 47.7735 17.8706 52.6132 17.8428 59.8727C18.4107 59.9372 18.9818 59.9703 19.5534 59.9719V59.9719Z"
        fill="#C0D8F0"
      />
      <path
        d="M78.4541 84.9161C80.4715 86.9622 81.835 89.4872 81.835 92.5829V117.842H83.3221C84.1247 117.842 84.8944 117.456 85.4619 116.767C86.0294 116.079 86.3482 115.145 86.3482 114.171V79.5469C85.8418 80.3015 81.1931 81.7284 78.4541 84.9161Z"
        fill="#C5CFD9"
      />
      <path
        d="M37.4434 122.269V90.9555C37.4434 83.1191 49.6115 80.3749 50.536 79.1621V118.12C50.536 119.216 50.1129 120.267 49.36 121.042C48.607 121.817 47.5858 122.252 46.5209 122.252L37.4434 122.269Z"
        fill="#C0D8F0"
      />
      <path
        d="M11.3379 110.871V88.0573C11.3379 82.3488 20.4666 80.3455 21.1731 79.4648V107.86C21.1731 108.659 20.8557 109.425 20.2907 109.99C19.7258 110.554 18.9596 110.871 18.1606 110.871H11.3379Z"
        fill="#C5CFD9"
      />
      <path
        d="M64.1738 78.6885C69.6352 79.8786 81.8081 83.2902 81.8081 90.9028V121.337"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.9966 78.6885C49.5353 79.8786 37.3623 83.2902 37.3623 90.9028V121.337"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.9972 73.4277V78.688C53.104 79.1957 50.4805 80.0129 50.4805 80.9333C50.4805 82.4764 55.3266 86.5227 59.5854 86.5227C63.8441 86.5227 68.6903 82.4764 68.6903 80.9333C68.6903 80.0129 66.0668 79.1957 64.1736 78.688V73.4872"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5226 79.5176C20.4266 80.3982 11.2979 82.9688 11.2979 88.6773V110.793"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4209 79.5176C33.9809 80.069 38.5056 81.2789 41.5419 83.4846"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4212 75.5703V79.5373C32.8382 79.934 34.8108 80.529 34.8108 81.2193C34.8108 82.3776 31.1711 85.4124 27.9801 85.4124C24.789 85.4124 21.1494 82.3776 21.1494 81.2193C21.1494 80.529 23.1339 79.9181 24.539 79.5373V75.6377"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9648 76.3122C24.2776 76.3122 17.7803 71.3336 17.7803 60.6863C17.7803 53.387 22.3407 48.5195 27.9648 48.5195C33.5889 48.5195 38.1452 53.387 38.1452 60.6863C38.1452 71.3535 31.8187 76.3122 27.9648 76.3122Z"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.6475 79.5176C98.7435 80.3982 107.872 82.9688 107.872 88.6773V110.793"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.7811 79.5176C85.2211 80.069 80.6964 81.2789 77.6602 83.4846"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.7802 75.5703V79.5373C86.3593 79.934 84.3906 80.529 84.3906 81.2193C84.3906 82.3776 88.0302 85.4124 91.2213 85.4124C94.4124 85.4124 98.052 82.3776 98.052 81.2193C98.052 80.529 96.0675 79.9181 94.6624 79.5373V75.6377"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.2058 76.3122C94.893 76.3122 101.39 71.3336 101.39 60.6863C101.39 53.387 96.8299 48.5195 91.2058 48.5195C85.5817 48.5195 81.0332 53.387 81.0332 60.6863C81.0332 71.3535 87.3439 76.3122 91.2058 76.3122Z"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.6932 49.4795C59.4696 52.3696 57.2821 54.7469 54.5028 56.2071C51.7235 57.6673 48.5239 58.1202 45.4482 57.4888"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.873 54.8701C33.0456 57.6459 35.1059 59.9548 37.7313 61.4355"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.2354 73.1429C56.4978 74.0988 58.0194 74.6518 59.6013 74.7297C63.5346 74.7297 70.4605 69.4219 70.4605 58.0684"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.7217 58.0518C48.7217 65.8627 51.8969 70.8096 55.2388 73.1263"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.1415 74.717C70.4007 74.7208 73.6372 74.1749 76.7147 73.1024C75.0719 67.278 74.2412 61.2548 74.246 55.2034C74.246 45.4724 67.1018 38.9824 59.5964 38.9824C52.091 38.9824 44.915 45.4724 44.915 55.2034C44.9217 61.255 44.0909 67.2784 42.4463 73.1024C45.5238 74.1749 48.7603 74.7208 52.0195 74.717"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9258 60.3653C20.5358 60.9225 23.2523 60.6985 25.7357 59.7213C28.219 58.7441 30.3593 57.0571 31.8888 54.8711"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.3828 64.7565C83.5451 62.4701 85.1279 59.6992 85.9988 56.6758C87.7002 56.8027 89.4359 56.8675 91.2061 56.8702C92.9763 56.8728 94.7121 56.808 96.4135 56.6758C97.2868 59.6993 98.8693 62.4709 101.029 64.7605"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default SvgPeople;
