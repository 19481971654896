const SvgLab = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="mask0_9365_25132"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={120}
      height={120}
    >
      <circle cx={60} cy={60} r={60} fill="#F0F5FC" />
    </mask>
    <g mask="url(#mask0_9365_25132)">
      <circle cx={60.2642} cy={61.5074} r={60} fill="#F6F9FC" />
    </g>
    <mask
      id="mask1_9365_25132"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={120}
      height={120}
    >
      <circle cx={60} cy={60} r={60} fill="#F0F5FC" />
    </mask>
    <g mask="url(#mask1_9365_25132)">
      <path d="M8 90L112 90" stroke="#36485C" strokeWidth={1.67} />
      <path
        d="M46.8655 68.4897L68.3498 68.3296L68.5056 89.2338L44.6915 89.4112C33.5107 89.6846 35.6704 83.0643 38.1478 79.72L46.8655 68.4897Z"
        fill="#CAD6DF"
      />
      <path
        d="M54.9106 32.8633V56.0891L37.7924 80.4879C35.327 84.4744 32.0539 89.6375 45.0347 89.6375H78.6128C81.8316 89.4811 87.0402 87.4322 82.1242 80.4879C77.2082 73.5436 68.5174 61.3286 64.7866 56.0891V32.8633"
        stroke="#36485C"
        strokeWidth={1.67}
        strokeLinecap="round"
      />
      <path d="M50.7666 32L69.2961 32" stroke="#36485C" strokeWidth={1.67} strokeLinecap="round" />
      <path d="M46.3809 68.5469L73.5809 68.5469" stroke="#36485C" strokeWidth={1.67} />
    </g>
  </svg>
);
export default SvgLab;
