const SvgPastSwaps = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_485_4157)">
      <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
      <path
        d="M30.0303 59.1623C30.0303 57.0469 31.7451 55.332 33.8605 55.332H47.7551V77.1083H30.0303V59.1623Z"
        fill="#58728C"
      />
      <rect x={27.3965} y={42.9414} width={18.0483} height={4.89883} rx={0.687984} fill="white" />
      <rect
        x={42.6895}
        y={55.332}
        width={5.06424}
        height={22.0295}
        fill="white"
        fillOpacity={0.35}
      />
      <path
        d="M43.7796 51.0026V48.123H44.5448C45.2499 48.123 45.8215 47.5514 45.8215 46.8462V43.9486C45.8215 43.2435 45.2499 42.6719 44.5448 42.6719H28.3853C27.6802 42.6719 27.1086 43.2435 27.1086 43.9486V46.8462C27.1086 47.5514 27.6802 48.123 28.3853 48.123H29.1505V51.0451C28.0301 51.4606 27.0638 52.208 26.3815 53.187C25.6991 54.1659 25.3332 55.3296 25.333 56.5219V76.0163C25.333 76.7214 25.9046 77.293 26.6098 77.293H46.4438C47.1489 77.293 47.7205 76.7214 47.7205 76.0163V56.5246C47.7205 55.3098 47.3409 54.1252 46.6346 53.1356C45.9283 52.1459 44.9304 51.4004 43.7796 51.0026Z"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.7997 48.1074L29.3887 48.1074"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9502 59.1623C76.9502 57.0469 78.6651 55.332 80.7804 55.332H94.675V77.1083H76.9502V59.1623Z"
        fill="#B9D9A5"
      />
      <rect x={74.3164} y={42.9414} width={18.0483} height={4.89883} rx={0.687984} fill="white" />
      <rect
        x={89.6094}
        y={55.332}
        width={5.06424}
        height={22.0295}
        fill="white"
        fillOpacity={0.35}
      />
      <path
        d="M90.6995 51.0026V48.123H91.4647C92.1698 48.123 92.7415 47.5514 92.7415 46.8462V43.9486C92.7415 43.2435 92.1698 42.6719 91.4647 42.6719H75.3053C74.6001 42.6719 74.0285 43.2435 74.0285 43.9486V46.8462C74.0285 47.5514 74.6001 48.123 75.3053 48.123H76.0704V51.0451C74.95 51.4606 73.9837 52.208 73.3014 53.187C72.619 54.1659 72.2532 55.3296 72.2529 56.5219V76.0163C72.2529 76.7214 72.8246 77.293 73.5297 77.293H93.3637C94.0688 77.293 94.6405 76.7214 94.6405 76.0163V56.5246C94.6404 55.3098 94.2609 54.1252 93.5546 53.1356C92.8483 52.1459 91.8503 51.4004 90.6995 51.0026Z"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.7196 48.1074L76.3086 48.1074"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1_485_4157)">
        <path
          d="M80.8262 30.3357C74.5653 25.7985 67.0308 23.3555 59.2987 23.3555C51.5666 23.3555 44.0322 25.7985 37.7713 30.3357"
          stroke="#58728C"
          strokeWidth={1.66667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.1306 23.3105L81.9326 31.0343L73.9613 31.949"
          stroke="#58728C"
          strokeWidth={1.66667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.868 89.6904C44.1289 94.2276 51.6633 96.6706 59.3954 96.6706C67.1275 96.6706 74.6619 94.2276 80.9229 89.6904"
          stroke="#58728C"
          strokeWidth={1.66667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.4234 96.4421L36.6283 88.7253L44.5996 87.8105"
          stroke="#58728C"
          strokeWidth={1.66667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_485_4157">
        <rect width={120} height={120} fill="white" />
      </clipPath>
      <clipPath id="clip1_485_4157">
        <rect
          width={47.2778}
          height={74.6995}
          fill="white"
          transform="matrix(-1 0 0 1 82.9863 22.666)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPastSwaps;
