const SvgEmptyBottle = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <path
      d="M28.045 28.4699L35.0284 23.0909C36.0753 22.3144 38.6229 21.3272 40.4378 23.5902C42.2527 25.8533 57.0128 45.0702 64.1659 54.3958L54.0288 62.204L28.045 28.4699Z"
      fill="#F6F9FC"
    />
    <path
      d="M29.6895 27.2041L55.7352 61.0186L41.6137 71.8958L18.2317 41.5395C17.1726 40.5186 15.799 37.9033 18.7774 35.6092L29.6895 27.2041Z"
      fill="#58728C"
    />
    <path
      d="M21.1963 33.7461L47.242 67.5606L41.6134 71.8961L18.2314 41.5399C17.1729 40.5185 15.7979 37.9042 18.7657 35.6182L21.1963 33.7461Z"
      fill="white"
      fillOpacity={0.35}
    />
    <path
      d="M18.6948 35.6719L35.0813 23.0501C36.7932 21.7315 39.2522 22.0506 40.5708 23.7625L64.251 54.5059L41.6599 71.9067L17.9798 41.1634C16.6611 39.4514 16.9803 36.9925 18.6948 35.6719Z"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.5046 79.5645L69.6533 64.0449L63.0286 55.4442L42.8799 70.9638L49.5046 79.5645Z"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={82.7409}
      y={41.8201}
      width={7.30955}
      height={23.7075}
      rx={3.65477}
      transform="rotate(11.9742 82.7409 41.8201)"
      fill="#F0BDC8"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <circle
      cx={79.3617}
      cy={73.8591}
      r={3.54718}
      transform="rotate(11.9742 79.3617 73.8591)"
      fill="#F0BDC8"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <rect
      x={49.6673}
      y={89.3333}
      width={29}
      height={11.6667}
      rx={0.833333}
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgEmptyBottle;
