const SvgPerson = props => (
  <svg viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx={60}
      cy={60}
      r={60}
      transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 120.5 0)"
      fill="#F6F9FC"
    />
    <mask
      id="mask0_8954_1636"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={2}
      width={119}
      height={119}
    >
      <path
        d="M1.75765 61.1668C1.75764 93.6596 28.115 120 60.6284 120C93.1417 120 119.499 93.6596 119.499 61.1668C119.499 40.7515 102.912 2.33349 60.6284 2.33349C28.1062 2.33349 1.75765 28.6741 1.75765 61.1668Z"
        fill="#F6F9FC"
      />
    </mask>
    <g mask="url(#mask0_8954_1636)">
      <path
        d="M75.5443 54.4603C75.5443 55.2537 75.5575 56.0471 75.584 56.8405C73.1174 57.2127 70.5978 56.9975 68.2301 56.2125C65.8625 55.4275 63.7137 54.0949 61.9587 52.323C60.2037 50.5511 58.8921 48.3901 58.1307 46.0159C57.3692 43.6417 57.1793 41.1213 57.5766 38.6598C58.6591 38.38 59.7726 38.2374 60.8907 38.2354C68.3723 38.2393 75.5443 44.7293 75.5443 54.4603Z"
        fill="#C5CFD9"
      />
      <path
        d="M83.0566 122.268L83.0566 90.9548C83.0566 83.1184 70.8885 80.3742 69.964 79.1614L69.964 118.119C69.964 119.215 70.3871 120.266 71.14 121.041C71.893 121.816 72.9142 122.252 73.9791 122.252L83.0566 122.268Z"
        fill="#C0D8F0"
      />
      <path
        d="M56.3267 78.6875C50.8653 79.8776 38.6924 83.2892 38.6924 90.9018L38.6924 121.336"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5034 78.6875C70.9647 79.8776 83.1377 83.2892 83.1377 90.9018L83.1377 121.337"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5028 73.427L65.5028 78.6872C67.396 79.195 70.0195 80.0122 70.0195 80.9325C70.0195 82.4757 65.1734 86.522 60.9146 86.522C56.6559 86.522 51.8097 82.4757 51.8097 80.9325C51.8097 80.0122 54.4332 79.195 56.3264 78.6872L56.3264 73.4865"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.8068 49.4788C61.0304 52.3689 63.2179 54.7462 65.9972 56.2064C68.7765 57.6665 71.9761 58.1195 75.0518 57.4881"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.2646 73.1417C64.0022 74.0976 62.4806 74.6506 60.8987 74.7285C56.9654 74.7285 50.0395 69.4206 50.0395 58.0671"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.7783 58.051C71.7783 65.862 68.6031 70.8088 65.2612 73.1255"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.3585 74.7165C50.0993 74.7203 46.8628 74.1744 43.7853 73.1019C45.428 67.2775 46.2588 61.2543 46.254 55.2029C46.254 45.4719 53.3982 38.9819 60.9036 38.9819C68.409 38.9819 75.585 45.4719 75.585 55.2029C75.5783 61.2545 76.409 67.2779 78.0537 73.1019C74.9762 74.1744 71.7397 74.7203 68.4805 74.7165"
        stroke="#36485C"
        strokeWidth={1.92538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default SvgPerson;
