const SvgSelect = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 -1.32224e-05 93.1371 -1.32224e-05 60C-1.32224e-05 26.8629 26.8629 6.15765e-06 60 6.15765e-06C93.1371 6.15765e-06 120 26.8629 120 60Z"
      fill="#F6F9FC"
    />
    <mask
      id="mask0_485_4107"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={120}
      height={120}
    >
      <path
        d="M119.777 59.9723C119.777 93.0019 93.0009 119.778 59.9713 119.778C26.9418 119.778 0.166002 93.0019 0.166002 59.9723C0.166002 26.9428 26.9418 0.166998 59.9713 0.166998C93.0009 0.166998 119.777 26.9428 119.777 59.9723Z"
        fill="#F6F9FC"
      />
    </mask>
    <g mask="url(#mask0_485_4107)">
      <circle opacity={0.5} cx={57.6667} cy={54.8327} r={30.1667} fill="#C0D8F0" />
      <ellipse
        cx={59.0653}
        cy={55.0337}
        rx={17.0506}
        ry={15.6025}
        transform="rotate(32.2648 59.0653 55.0337)"
        fill="#C0D8F0"
      />
      <path
        d="M71.166 71.5L72.9993 75.3333"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M35.499 47.833C35.499 47.833 34.3978 49.7585 34.5066 54.4997"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M38.166 42.1038C38.166 42.1038 46.722 28.6183 63.3995 32.3055C78.3024 35.6034 80.666 51.667 80.666 51.667"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M36.833 64.667C36.833 64.667 39.88 72.7479 51.833 77.167"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M108.721 103.71L90.7474 113.414L86.9805 107.234C85.6341 105.24 84.7512 104.519 82.3601 104.276L79.8072 104.497C78.6815 104.387 77.6074 103.989 76.6804 103.349L56.345 89.238C54.6528 88.0755 54.2334 85.7507 55.4179 84.0659C56.551 82.4473 58.7581 82.0058 60.4282 83.0726L74.613 91.6291L57.2412 59.7792C56.2185 57.7928 57.7312 54.1136 62.147 55.2819C63.9652 56.0613 73.8456 77.8787 71.1245 71.8114C70.5692 70.573 68.9594 67.6199 68.9594 67.6199C68.6136 66.5605 68.0639 64.4086 71.1245 62.9445C74.1851 61.4804 75.9656 64.7471 77.025 66.0051C76.3408 64.3277 76.9396 60.9638 79.0953 60.1619C81.2436 59.3526 83.1638 60.4194 84.0099 62.1704L85.7315 65.2825C85.7315 65.2825 85.0343 62.3488 86.9805 61.077C90.3591 58.8691 92.5958 62.7737 93.979 65.3046L102.35 80.7257C104.439 84.5441 105.683 88.7671 105.999 93.1079L106.271 96.8748C106.389 97.8312 106.573 98.7877 106.823 99.7147L108.721 103.71Z"
        fill="#F6F9FC"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M93.9416 117.573L87.2969 113.918L111.263 100.524L112.247 105.954L93.9416 117.573Z"
        fill="#F6F9FC"
      />
      <path
        d="M88.4014 109.484L91.7121 115.002"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
      />
      <path
        d="M107.897 102.128L110.105 105.807"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
      />
      <line
        x1={76.7764}
        y1={65.5618}
        x2={80.8942}
        y2={74.2233}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
      />
      <line
        x1={84.9389}
        y1={63.7414}
        x2={88.2404}
        y2={70.8948}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
      />
      <line
        x1={69.6156}
        y1={68.7128}
        x2={74.0465}
        y2={77.7174}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
      />
    </g>
  </svg>
);
export default SvgSelect;
