const SvgStaff = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_485_4182)">
      <path
        d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60Z"
        fill="#F6F9FC"
      />
      <path
        d="M22.344 36.9646C22.2171 35.405 23.3786 34.0377 24.9383 33.9108L81.9067 29.2753C83.4664 29.1484 84.8336 30.3098 84.9605 31.8695L85.7338 41.3726L23.1173 46.4678L22.344 36.9646Z"
        fill="#C5CFD9"
      />
      <rect
        x={22.7322}
        y={34.9261}
        width={62}
        height={42.6049}
        rx={2}
        transform="rotate(-4.65195 22.7322 34.9261)"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinejoin="round"
      />
      <line
        x1={23.8802}
        y1={45.5696}
        x2={84.8355}
        y2={40.6096}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={41.3782}
        y1={38.7874}
        x2={67.033}
        y2={36.6998}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={54.5296}
        y1={51.3948}
        x2={65.7559}
        y2={50.4813}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={55.3851}
        y1={61.9016}
        x2={81.32}
        y2={59.7913}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={55.8636}
        y1={67.7844}
        x2={77.4559}
        y2={66.0275}
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={38.8435}
        cy={56.0455}
        r={4.92899}
        transform="rotate(-4.65195 38.8435 56.0455)"
        fill="#C5CFD9"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-10-inside-1_485_4182" fill="white">
        <path d="M48.0606 69.3263C48.7028 69.2741 49.1883 68.7085 49.0571 68.0777C48.6364 66.0554 47.5595 64.2176 45.9795 62.8651C44.1035 61.2592 41.672 60.4691 39.22 60.6686C36.768 60.8681 34.4962 62.0409 32.9045 63.929C31.564 65.5191 30.7983 67.5069 30.7101 69.5706C30.6826 70.2143 31.2532 70.694 31.8954 70.6417L39.978 69.984L48.0606 69.3263Z" />
      </mask>
      <path
        d="M48.0606 69.3263C48.7028 69.2741 49.1883 68.7085 49.0571 68.0777C48.6364 66.0554 47.5595 64.2176 45.9795 62.8651C44.1035 61.2592 41.672 60.4691 39.22 60.6686C36.768 60.8681 34.4962 62.0409 32.9045 63.929C31.564 65.5191 30.7983 67.5069 30.7101 69.5706C30.6826 70.2143 31.2532 70.694 31.8954 70.6417L39.978 69.984L48.0606 69.3263Z"
        fill="#C5CFD9"
        stroke="#36485C"
        strokeWidth={3.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#path-10-inside-1_485_4182)"
      />
      <path
        opacity={0.35}
        d="M81.2575 90.3839L93.0531 80.0618C94.7168 78.606 95.3742 76.6355 94.5216 75.6605L94.521 75.6598C93.6684 74.6849 91.6286 75.0748 89.9649 76.5306L78.1693 86.8527C76.5056 88.3085 75.8481 90.279 76.7007 91.2539L76.7014 91.2546C77.554 92.2295 79.5938 91.8397 81.2575 90.3839Z"
        fill="#60768F"
      />
      <path
        d="M83.099 92.6506L95.101 82.1479C96.7938 80.6666 96.9655 78.0932 95.4847 76.3999L95.4836 76.3987C94.0028 74.7054 91.43 74.5336 89.7373 76.0149L77.7352 86.5176C76.0425 87.9989 75.8707 90.5723 77.3515 92.2656L77.3526 92.2668C78.8335 93.9601 81.4062 94.1319 83.099 92.6506Z"
        stroke="#36485C"
        strokeWidth={1.66686}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.8559 80.5874C80.5235 79.3385 81.6736 76.1635 80.4247 73.4959C79.1757 70.8283 76.0007 69.6782 73.3331 70.9271C70.6655 72.176 69.5154 75.351 70.7643 78.0187C72.0132 80.6863 75.1882 81.8364 77.8559 80.5874Z"
        fill="white"
        stroke="#36485C"
        strokeWidth={1.66686}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.1064 72.2383L79.3846 79.1364"
        stroke="#36485C"
        strokeWidth={1.66686}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_485_4182">
        <rect width={120} height={120} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStaff;
