const SvgClientOrders = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_485_4075)">
      <path
        d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60Z"
        fill="#F6F9FC"
      />
      <path
        d="M98.2892 81.9532C93.6078 90.0216 85.7313 89.8619 82.3782 88.7735C82.8378 85.8054 93.6639 71.6681 99.0195 64.9705C104.036 71.8259 100.623 79.1487 98.2892 81.9532Z"
        fill="#D1E0D9"
      />
      <path
        d="M82.9318 71.7433C87.6207 63.662 95.5097 63.822 98.8681 64.9121C98.4077 67.8849 87.5644 82.0447 82.2003 88.753C77.1754 81.8867 80.5943 74.5522 82.9318 71.7433Z"
        fill="#B6CEC2"
      />
      <path
        d="M83.0392 71.3768C87.4036 64.6172 95.7246 63.9307 99.3395 64.4324C103.424 73.268 100.09 80.3208 98.352 82.6766C91.9813 89.6191 85.2446 89.638 82.6725 88.7796C77.6657 81.7582 80.8308 74.2522 83.0392 71.3768Z"
        stroke="#36485C"
        strokeWidth={1.66126}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.9033 87.5371L93.6246 72.7208"
        stroke="#36485C"
        strokeWidth={1.66126}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1969 35.5493V30.626H53.5352V35.5493H50.2572L54.1295 43.404V51.2858H41.2445H32.0605V41.7591L36.1024 37.9363V35.5493H33.1969Z"
        fill="white"
        stroke="#36485C"
        strokeWidth={0.557195}
      />
      <path
        d="M31.9697 51.587V43.1821C31.9697 41.3625 33.443 38.6549 35.2653 38.6549C35.4147 38.6546 35.5626 38.6248 35.7004 38.5671C35.8383 38.5095 35.9634 38.4252 36.0685 38.3191C36.1737 38.213 36.2569 38.0871 36.3133 37.9488C36.3697 37.8104 36.3982 37.6623 36.3972 37.5129V35.9346H50.7473V37.5129C50.7463 37.6629 50.775 37.8117 50.8319 37.9506C50.8888 38.0894 50.9726 38.2156 51.0786 38.3218C51.1846 38.428 51.3106 38.5122 51.4494 38.5693C51.5881 38.6265 51.7368 38.6556 51.8869 38.6549"
        fill="white"
      />
      <path
        d="M31.9697 51.587V43.1821C31.9697 41.3625 33.443 38.6549 35.2653 38.6549C35.4147 38.6546 35.5626 38.6248 35.7004 38.5671C35.8383 38.5095 35.9634 38.4252 36.0685 38.3191C36.1737 38.213 36.2569 38.0871 36.3133 37.9488C36.3697 37.8104 36.3982 37.6623 36.3972 37.5129V35.9346H50.7473V37.5129C50.7463 37.6629 50.775 37.8117 50.8319 37.9506C50.8888 38.0894 50.9726 38.2156 51.0786 38.3218C51.1846 38.428 51.3106 38.5122 51.4494 38.5693C51.5881 38.6265 51.7368 38.6556 51.8869 38.6549"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7126 35.9344C35.7126 35.5559 36.0195 35.249 36.398 35.249H50.7481C51.1266 35.249 51.4334 35.5559 51.4334 35.9344V37.5127L51.4334 37.5174C51.433 37.5768 51.4444 37.6356 51.4669 37.6906C51.4894 37.7455 51.5226 37.7955 51.5646 37.8375C51.6065 37.8796 51.6564 37.9129 51.7113 37.9355C51.7663 37.9582 51.8251 37.9697 51.8845 37.9694C52.2631 37.9677 52.5713 38.2731 52.573 38.6517C52.5747 39.0302 52.2693 39.3384 51.8908 39.3401C51.6501 39.3412 51.4115 39.2946 51.189 39.2028C50.9664 39.1111 50.7643 38.9761 50.5943 38.8057C50.4242 38.6354 50.2897 38.4329 50.1985 38.2102C50.1076 37.9883 50.0614 37.7506 50.0627 37.5108V36.6198H37.0834V37.5107C37.0846 37.7494 37.0389 37.9862 36.9488 38.2073C36.8583 38.4293 36.7249 38.6311 36.5561 38.8014C36.3874 38.9716 36.1867 39.1068 35.9656 39.1993C35.7445 39.2917 35.5073 39.3396 35.2676 39.3401L35.2661 39.3401C34.7014 39.3401 34.0575 39.7783 33.5072 40.6132C32.9715 41.4259 32.6559 42.4286 32.6559 43.1819V51.5868C32.6559 51.9653 32.349 52.2722 31.9705 52.2722C31.592 52.2722 31.2852 51.9653 31.2852 51.5868V43.1819C31.2852 42.1156 31.7062 40.8548 32.3627 39.8588C33.0044 38.8852 34.0079 37.9699 35.2651 37.9694C35.3241 37.9692 35.3824 37.9574 35.4368 37.9346C35.4914 37.9118 35.5409 37.8784 35.5826 37.8364C35.6242 37.7944 35.6571 37.7446 35.6794 37.6899C35.7017 37.6351 35.713 37.5765 35.7126 37.5174L35.7126 37.5127V35.9344Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.9356 30.5195H34.212C33.5755 30.5195 33.0596 31.0354 33.0596 31.6718V34.7823C33.0596 35.4187 33.5755 35.9346 34.212 35.9346H52.9356C53.5721 35.9346 54.0881 35.4187 54.0881 34.7823V31.6718C54.0881 31.0354 53.5721 30.5195 52.9356 30.5195Z"
        fill="#FCF3DD"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2099 31.2047C33.9519 31.2047 33.7428 31.4138 33.7428 31.6717V34.7821C33.7428 35.0399 33.9519 35.2491 34.2099 35.2491H52.9335C53.1915 35.2491 53.4006 35.0399 53.4006 34.7821V31.6717C53.4006 31.4138 53.1915 31.2047 52.9335 31.2047H34.2099ZM32.3721 31.6717C32.3721 30.6567 33.1949 29.834 34.2099 29.834H52.9335C53.9484 29.834 54.7713 30.6567 54.7713 31.6717V34.7821C54.7713 35.7971 53.9484 36.6198 52.9335 36.6198H34.2099C33.1949 36.6198 32.3721 35.7971 32.3721 34.7821V31.6717Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.667 51.1659C49.667 49.3438 51.1428 46.6363 52.9626 46.6363C53.2625 46.6356 53.5501 46.5161 53.7622 46.304C53.9743 46.0919 54.0938 45.8044 54.0944 45.5045V43.9287H64.8743V45.5173C64.8784 45.8146 64.999 46.0984 65.2102 46.3077C65.4214 46.5169 66.7401 46.8998 66.7401 46.8998L62.765 51.1659"
        fill="white"
      />
      <path
        d="M49.667 51.1659C49.667 49.3438 51.1428 46.6363 52.9626 46.6363C53.2625 46.6356 53.5501 46.5161 53.7622 46.304C53.9743 46.0919 54.0938 45.8044 54.0944 45.5045V43.9287H64.8743V45.5173C64.8784 45.8146 64.999 46.0984 65.2102 46.3077C65.4214 46.5169 66.7401 46.8998 66.7401 46.8998L62.765 51.1659"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4099 43.9285C53.4099 43.55 53.7167 43.2432 54.0953 43.2432H64.8751C65.2537 43.2432 65.5605 43.55 65.5605 43.9285V45.5114C65.5627 45.6147 65.6005 45.7138 65.6669 45.7921C65.6975 45.8084 65.744 45.8306 65.8078 45.8578C65.9389 45.9136 66.1057 45.9749 66.2771 46.0336C66.4465 46.0916 66.6109 46.1438 66.7336 46.1817C66.7948 46.2005 66.845 46.2157 66.8797 46.226C66.8971 46.2312 66.9105 46.2352 66.9194 46.2378L66.9293 46.2407L66.932 46.2415C67.1606 46.3078 67.3384 46.488 67.4016 46.7175C67.4649 46.9469 67.4046 47.1928 67.2424 47.3669L63.2672 51.633C63.0091 51.9099 62.5755 51.9252 62.2985 51.6672C62.0216 51.4091 62.0063 50.9755 62.2643 50.6985L65.5105 47.2148C65.4279 47.1835 65.3467 47.1513 65.2705 47.1188C65.1831 47.0816 65.0929 47.0401 65.0108 46.9956C64.9413 46.958 64.8268 46.8916 64.7286 46.7944C64.3898 46.4587 64.1963 46.0033 64.1898 45.5264C64.1898 45.5233 64.1898 45.5202 64.1898 45.5171V44.6139H54.7806V45.5043L54.7806 45.5058C54.7795 45.9871 54.5879 46.4482 54.2476 46.7885C53.9073 47.1287 53.4461 47.3204 52.9649 47.3214L52.9634 47.3215C52.4002 47.3215 51.7562 47.7594 51.2054 48.5949C50.6693 49.4079 50.3532 50.4113 50.3532 51.1658C50.3532 51.5443 50.0463 51.8511 49.6678 51.8511C49.2893 51.8511 48.9824 51.5443 48.9824 51.1658C48.9824 50.0981 49.4042 48.8366 50.061 47.8404C50.7028 46.8668 51.7063 45.9512 52.9623 45.9507C53.0809 45.9503 53.1945 45.903 53.2784 45.8192C53.3622 45.7354 53.4094 45.6218 53.4099 45.5034V43.9285ZM65.6423 45.7779C65.6423 45.7779 65.6435 45.7786 65.6456 45.78C65.6433 45.7786 65.6422 45.7779 65.6423 45.7779Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.541 38.5H52.422C51.7855 38.5 51.2695 39.0159 51.2695 39.6523V42.7628C51.2695 43.3992 51.7855 43.9151 52.422 43.9151H66.541C67.1775 43.9151 67.6935 43.3992 67.6935 42.7628V39.6523C67.6935 39.0159 67.1775 38.5 66.541 38.5Z"
        fill="#FCF3DD"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.4218 39.1852C52.1638 39.1852 51.9547 39.3943 51.9547 39.6521V42.7626C51.9547 43.0204 52.1638 43.2295 52.4218 43.2295H66.5409C66.7989 43.2295 67.0079 43.0204 67.0079 42.7626V39.6521C67.0079 39.3943 66.7989 39.1852 66.5409 39.1852H52.4218ZM50.584 39.6521C50.584 38.6371 51.4069 37.8145 52.4218 37.8145H66.5409C67.5558 37.8145 68.3787 38.6371 68.3787 39.6521V42.7626C68.3787 43.7776 67.5558 44.6003 66.5409 44.6003H52.4218C51.4069 44.6003 50.584 43.7776 50.584 42.7626V39.6521Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5215 51.5272C24.5215 51.1486 24.8283 50.8418 25.2069 50.8418H62.6282C63.0067 50.8418 63.3136 51.1486 63.3136 51.5272V89.7709C63.3136 90.1494 63.0067 90.4563 62.6282 90.4563H28.4966C26.3012 90.4563 24.5215 88.6766 24.5215 86.4811V51.5272ZM25.8922 52.2125V86.4811C25.8922 87.9195 27.0583 89.0856 28.4966 89.0856H61.9428V52.2125H25.8922Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9424 51.5272C61.9424 51.1486 62.2492 50.8418 62.6278 50.8418H81.407C81.7855 50.8418 82.0923 51.1486 82.0923 51.5272V86.4811C82.0923 88.6766 80.3126 90.4563 78.1172 90.4563H62.6278C62.2492 90.4563 61.9424 90.1494 61.9424 89.7709V51.5272ZM63.3131 52.2125V89.0856H78.1172C79.5555 89.0856 80.7216 87.9195 80.7216 86.4811V52.2125H63.3131Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.6926 39.1852C75.5833 39.1852 75.4784 39.2288 75.4012 39.3063L63.8505 50.9082H80.6733L91.6463 39.8866C91.9044 39.6272 91.7208 39.1852 91.3548 39.1852H75.6926ZM74.4298 38.3392C74.7642 38.0033 75.2187 37.8145 75.6926 37.8145H91.3548C92.9405 37.8145 93.7364 39.73 92.6177 40.8537L81.4437 52.0771C81.3151 52.2063 81.1403 52.2789 80.958 52.2789H62.201C61.9241 52.2789 61.6744 52.1123 61.5681 51.8565C61.4618 51.6008 61.5199 51.3062 61.7153 51.11L74.4298 38.3392Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3557 40.8537C12.237 39.73 13.0329 37.8145 14.6185 37.8145H30.2802C30.7547 37.8145 31.2096 38.0037 31.5441 38.3403L33.2399 40.0467C33.5067 40.3152 33.5054 40.7492 33.2369 41.016C32.9684 41.2828 32.5344 41.2814 32.2676 41.013L30.5718 39.3066C30.4947 39.2289 30.3897 39.1852 30.2802 39.1852H14.6185C14.2526 39.1852 14.0689 39.6272 14.3271 39.8866L25.5011 51.11C25.7681 51.3782 25.7672 51.8122 25.4989 52.0792C25.2307 52.3463 24.7967 52.3453 24.5297 52.0771L13.3557 40.8537Z"
        fill="#36485C"
        stroke="#36485C"
        strokeWidth={0.278597}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1_485_4075)">
        <path
          d="M49.2149 68.9703C49.2149 68.9703 49.2149 68.9957 49.2149 69.0211C49.2149 69.2242 48.9042 73.4887 43.5446 75.4179V72.7526L46.7811 69.3003C47.0659 68.9957 47.0659 68.5134 46.7293 68.2088C46.4186 67.9296 45.9266 67.9296 45.6159 68.2596L43.5446 70.4934V68.2088C43.5446 68.2088 43.5446 68.2088 43.5446 68.1834C43.8035 67.5996 45.0463 65.315 48.1015 65.315C48.1274 65.315 48.1533 65.315 48.1792 65.315C49.3961 66.7365 49.2149 68.945 49.2149 68.9703ZM41.9911 70.4934L39.8938 68.2596C39.609 67.955 39.1171 67.9296 38.8064 68.2088C38.4957 68.488 38.4698 68.9957 38.7546 69.3003L41.9911 72.7526V75.3925C36.6315 73.4633 36.3466 69.1734 36.3208 68.9957C36.3208 68.9703 36.3208 68.9703 36.3208 68.945C36.3208 68.9196 36.1136 66.7112 37.3305 65.2896C37.3564 65.2896 37.3823 65.2896 37.4341 65.2896C40.5152 65.2896 41.7321 67.5996 41.9911 68.158C41.9911 68.158 41.9911 68.158 41.9911 68.1834V70.4934V70.4934ZM49.1113 63.995C48.9819 63.8681 48.8006 63.792 48.6194 63.7666C45.6936 63.5889 43.7776 65.0612 42.7678 66.4319C41.758 65.0612 39.8679 63.5889 36.9163 63.7666C36.735 63.7666 36.5538 63.8681 36.4243 63.995C34.5342 65.8481 34.7414 68.7927 34.7672 69.0719C34.7672 69.275 34.8967 70.7218 35.8547 72.3972C36.7609 73.9964 38.6769 76.1033 42.483 77.1694C42.5089 77.1694 42.5348 77.1694 42.5348 77.1948C42.5607 77.1948 42.5607 77.1948 42.5866 77.1948C42.6125 77.1948 42.6125 77.1948 42.6384 77.1948C42.6643 77.1948 42.716 77.1948 42.7419 77.1948C42.7678 77.1948 42.8196 77.1948 42.8455 77.1948C42.8714 77.1948 42.8714 77.1948 42.8973 77.1948C42.9232 77.1948 42.9232 77.1948 42.9491 77.1948C42.975 77.1948 43.0008 77.1948 43.0267 77.1694C50.0952 75.2148 50.7166 69.6303 50.7425 69.0973C50.7684 68.945 51.0273 65.8989 49.1113 63.995Z"
          fill="#36485C"
        />
      </g>
      <path
        d="M87.4192 72.5317C90.4007 81.3706 85.0886 87.1883 82.0598 88.9924C80.1309 86.6902 76.6361 69.2301 75.1298 60.7878C83.5921 61.5341 86.8487 68.928 87.4192 72.5317Z"
        fill="#D1E0D9"
      />
      <path
        d="M69.6175 77.3512C66.6313 68.4982 71.9518 62.6712 74.9854 60.8644C76.9174 63.1701 80.4177 80.658 81.9264 89.1137C73.4507 88.3662 70.1889 80.9606 69.6175 77.3512Z"
        fill="#B6CEC2"
      />
      <path
        d="M69.4128 77.0288C67.2066 69.291 72.1753 62.5812 74.9355 60.1935C84.2718 62.9466 87.3783 70.1022 88.0039 72.9623C89.0252 82.3294 84.5987 87.4077 82.2578 88.7761C73.6773 87.9127 70.1193 80.5848 69.4128 77.0288Z"
        stroke="#36485C"
        strokeWidth={1.66126}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.4756 87.7822L77.4011 69.9534"
        stroke="#36485C"
        strokeWidth={1.66126}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_485_4075">
        <rect width={120} height={120} fill="white" />
      </clipPath>
      <clipPath id="clip1_485_4075">
        <rect
          width={16.3119}
          height={13.7074}
          fill="white"
          transform="translate(34.6123 63.6396)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClientOrders;
