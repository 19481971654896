// keep in sync with Illustration.tsx

const AVIARY_ILLUSTRATIONS = {
  bell: "bell",
  document: "document",
  emptyBottle: "emptyBottle",
  emptyOrderBox: "emptyOrderBox",
  envelope: "envelope",
  handSelect: "handSelect",
  idCardAndSupplements: "idCardAndSupplements",
  lab: "lab",
  laptop: "laptop",
  lighthouse: "lighthouse",
  money: "money",
  mountains: "mountains",
  orderBox: "orderBox",
  paperPlane: "paperPlane",
  people: "people",
  person: "person",
  plant: "plant",
  productCard: "productCard",
  rocket: "rocket",
  supplements: "supplements",
  swapProducts: "swapProducts",
};

type AviaryIllustration = keyof typeof AVIARY_ILLUSTRATIONS;

export { AVIARY_ILLUSTRATIONS, type AviaryIllustration };
