const SvgFavoriteProducts = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#EBF2EF" />
    <path d="M65.9951 40.6025H73.3015V91.0678H65.9951V40.6025Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3848 40.6406C25.3848 40.1504 25.777 39.7529 26.2608 39.7529H74.0932C74.5771 39.7529 74.9693 40.1504 74.9693 40.6406V90.1744C74.9693 90.6647 74.5771 91.0621 74.0932 91.0621H30.4659C27.6597 91.0621 25.3848 88.757 25.3848 85.9135V40.6406ZM27.1369 41.5283V85.9135C27.1369 87.7765 28.6273 89.2867 30.4659 89.2867H73.2172V41.5283H27.1369Z"
      fill="#36485C"
      stroke="#36485C"
      strokeWidth={0.279503}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.2451 40.6406C73.2451 40.1504 73.534 39.7529 73.8904 39.7529H93.7271C94.0835 39.7529 94.3724 40.1504 94.3724 40.6406V85.9135C94.3724 88.757 92.6968 91.0621 90.6298 91.0621H73.8904C73.534 91.0621 73.2451 90.6647 73.2451 90.1744V40.6406ZM74.5357 41.5283V89.2867H90.6298C91.984 89.2867 93.0819 87.7765 93.0819 85.9135V41.5283H74.5357Z"
      fill="#36485C"
      stroke="#36485C"
      strokeWidth={0.279503}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_485_4127)">
      <path
        d="M58.0468 64.3872C58.0468 64.3872 58.0468 64.4202 58.0468 64.4531C58.0468 64.7166 57.6489 70.2508 50.785 72.7543V69.2955L54.9299 64.8155C55.2946 64.4202 55.2946 63.7943 54.8635 63.399C54.4656 63.0366 53.8356 63.0366 53.4377 63.4649L50.785 66.3637V63.399C50.785 63.399 50.785 63.399 50.785 63.366C51.1166 62.6084 52.7082 59.6437 56.621 59.6437C56.6541 59.6437 56.6873 59.6437 56.7205 59.6437C58.2789 61.4884 58.0468 64.3543 58.0468 64.3872ZM48.7955 66.3637L46.1096 63.4649C45.7448 63.0696 45.1148 63.0366 44.7169 63.399C44.319 63.7613 44.2858 64.4202 44.6506 64.8155L48.7955 69.2955V72.7214C41.9316 70.2178 41.5668 64.6507 41.5337 64.4202C41.5337 64.3872 41.5337 64.3872 41.5337 64.3543C41.5337 64.3213 41.2684 61.4554 42.8268 59.6107C42.86 59.6107 42.8932 59.6107 42.9595 59.6107C46.9054 59.6107 48.4639 62.6084 48.7955 63.3331C48.7955 63.3331 48.7955 63.3331 48.7955 63.366V66.3637V66.3637ZM57.9142 57.9307C57.7484 57.766 57.5163 57.6672 57.2842 57.6342C53.5372 57.4036 51.0834 59.3142 49.7902 61.0931C48.497 59.3142 46.0764 57.4036 42.2963 57.6342C42.0642 57.6342 41.8321 57.766 41.6663 57.9307C39.2457 60.3354 39.511 64.1566 39.5441 64.519C39.5441 64.7825 39.7099 66.6602 40.9368 68.8343C42.0974 70.9096 44.5511 73.6438 49.4255 75.0273C49.4586 75.0273 49.4918 75.0273 49.4918 75.0602C49.525 75.0602 49.525 75.0602 49.5581 75.0602C49.5913 75.0602 49.5913 75.0602 49.6244 75.0602C49.6576 75.0602 49.7239 75.0602 49.7571 75.0602C49.7902 75.0602 49.8565 75.0602 49.8897 75.0602C49.9229 75.0602 49.9229 75.0602 49.956 75.0602C49.9892 75.0602 49.9892 75.0602 50.0223 75.0602C50.0555 75.0602 50.0887 75.0602 50.1218 75.0273C59.1742 72.4908 59.97 65.2437 60.0032 64.5519C60.0363 64.3543 60.3679 60.4013 57.9142 57.9307Z"
        fill="#36485C"
      />
    </g>
    <path
      d="M18.7783 67.541H32.0571V70.0655C33.2688 70.5031 35.6923 72.0649 35.6923 74.8115V92.1799C35.7091 92.6343 35.4298 93.5431 34.1776 93.5431H16.8092C16.338 93.56 15.3955 93.3109 15.3955 92.1799V74.8115C15.4797 73.6334 16.274 71.0349 18.7783 70.0655V67.541Z"
      fill="#F6F9FC"
    />
    <rect x={17.2373} y={63.0332} width={16.4605} height={4.22923} rx={0.891825} fill="white" />
    <path
      d="M19.6768 80.4213C19.6768 76.862 22.5622 73.9766 26.1215 73.9766H35.6419V93.2949H19.6768V80.4213Z"
      fill="#86B09B"
    />
    <rect x={31.0811} y={73.9766} width={4.56147} height={19.543} fill="white" fillOpacity={0.35} />
    <path
      d="M32.0615 70.1346V67.58V67.58C33.0773 67.58 33.9007 66.7565 33.9007 65.7408V64.8924C33.9007 63.7059 32.9389 62.7441 31.7525 62.7441H19.1938C18.0074 62.7441 17.0456 63.7059 17.0456 64.8924V65.7408C17.0456 66.7565 17.869 67.58 18.8848 67.58V67.58V70.1723C17.8756 70.5409 17.0053 71.2039 16.3906 72.0724C15.776 72.9408 15.4465 73.9732 15.4463 75.0309V91.3093C15.4463 92.4958 16.4081 93.4576 17.5945 93.4576H33.463C34.6494 93.4576 35.6112 92.4958 35.6112 91.3093V75.0333C35.6112 73.9556 35.2693 72.9047 34.6331 72.0268C33.997 71.1488 33.0981 70.4874 32.0615 70.1346Z"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.08 67.5664L19.0996 67.5664"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="path-12-inside-1_485_4127" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.8994 39.7539C78.6233 39.7539 78.3994 39.9778 78.3994 40.2539V61.1316C78.3994 61.5297 78.8421 61.7682 79.1746 61.549L83.8372 58.4756C84.0042 58.3655 84.2206 58.3655 84.3876 58.4756L89.0502 61.549C89.3827 61.7682 89.8254 61.5297 89.8254 61.1316V40.2539C89.8254 39.9778 89.6016 39.7539 89.3254 39.7539H78.8994Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.8994 39.7539C78.6233 39.7539 78.3994 39.9778 78.3994 40.2539V61.1316C78.3994 61.5297 78.8421 61.7682 79.1746 61.549L83.8372 58.4756C84.0042 58.3655 84.2206 58.3655 84.3876 58.4756L89.0502 61.549C89.3827 61.7682 89.8254 61.5297 89.8254 61.1316V40.2539C89.8254 39.9778 89.6016 39.7539 89.3254 39.7539H78.8994Z"
      fill="#B6CEC2"
    />
    <path
      d="M79.1746 61.549L78.2573 60.1575L78.2573 60.1575L79.1746 61.549ZM83.8372 58.4756L82.92 57.084L82.92 57.084L83.8372 58.4756ZM84.3876 58.4756L85.3048 57.084L85.3048 57.084L84.3876 58.4756ZM89.0502 61.549L89.9675 60.1575L89.9675 60.1575L89.0502 61.549ZM80.0661 40.2539C80.0661 40.8982 79.5437 41.4206 78.8994 41.4206V38.0872C77.7028 38.0872 76.7327 39.0573 76.7327 40.2539H80.0661ZM80.0661 61.1316V40.2539H76.7327V61.1316H80.0661ZM78.2573 60.1575C79.033 59.6462 80.0661 60.2025 80.0661 61.1316H76.7327C76.7327 62.8569 78.6513 63.8902 80.0918 62.9406L78.2573 60.1575ZM82.92 57.084L78.2573 60.1575L80.0918 62.9406L84.7545 59.8671L82.92 57.084ZM85.3048 57.084C84.5814 56.6072 83.6434 56.6072 82.92 57.084L84.7545 59.8671C84.3649 60.1239 83.8599 60.1239 83.4703 59.8671L85.3048 57.084ZM89.9675 60.1575L85.3048 57.084L83.4703 59.8671L88.133 62.9406L89.9675 60.1575ZM88.1587 61.1316C88.1587 60.2025 89.1918 59.6462 89.9675 60.1575L88.133 62.9406C89.5736 63.8901 91.4921 62.857 91.4921 61.1316H88.1587ZM88.1587 40.2539V61.1316H91.4921V40.2539H88.1587ZM89.3254 41.4206C88.6811 41.4206 88.1587 40.8982 88.1587 40.2539H91.4921C91.4921 39.0573 90.522 38.0872 89.3254 38.0872V41.4206ZM78.8994 41.4206H89.3254V38.0872H78.8994V41.4206Z"
      fill="#36485C"
      mask="url(#path-12-inside-1_485_4127)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.1421 27.902C88.0302 27.902 87.9229 27.9466 87.844 28.0259L76.0297 39.8925H93.2363L104.46 28.6194C104.724 28.3541 104.536 27.902 104.162 27.902H88.1421ZM86.8504 27.0367C87.1925 26.6931 87.6573 26.5 88.1421 26.5H104.162C105.783 26.5 106.598 28.4592 105.453 29.6086L94.0244 41.0881C93.8928 41.2202 93.7141 41.2945 93.5276 41.2945H74.3426C74.0594 41.2945 73.804 41.124 73.6953 40.8624C73.5866 40.6009 73.646 40.2996 73.8458 40.0989L86.8504 27.0367Z"
      fill="#36485C"
      stroke="#36485C"
      strokeWidth={0.279503}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5356 29.6086C13.3913 28.4592 14.2054 26.5 15.8273 26.5H31.8462C32.3316 26.5 32.7969 26.6936 33.139 27.0379L45.9484 39.8581C46.2213 40.1327 46.2199 40.5766 45.9453 40.8495C45.6707 41.1224 45.2268 41.121 44.9539 40.8464L32.1445 28.0261C32.0656 27.9467 31.9582 27.902 31.8462 27.902H15.8273C15.453 27.902 15.2651 28.3541 15.5292 28.6194L26.958 40.0989C27.2312 40.3732 27.2302 40.8171 26.9559 41.0902C26.6815 41.3634 26.2376 41.3624 25.9645 41.0881L14.5356 29.6086Z"
      fill="#36485C"
      stroke="#36485C"
      strokeWidth={0.279503}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.4189 40.5181H89.1042L102.018 27.2646H92.3326L79.4189 40.5181Z"
      fill="#B6CEC2"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <defs>
      <clipPath id="clip0_485_4127">
        <rect
          width={20.8901}
          height={17.7884}
          fill="white"
          transform="translate(39.3457 57.4688)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFavoriteProducts;
