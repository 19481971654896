const SvgPrescriptionDoc = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <path
      d="M69.333 30.418V42.7513C69.2774 43.5846 69.7996 45.2513 72.333 45.2513H84.1663L69.333 30.418Z"
      fill="#CAD6DF"
      fillOpacity={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1515 30.4614C36.9819 29.6305 38.1772 29.334 39.5741 29.334H69.2063C69.4274 29.334 69.6395 29.4219 69.7958 29.5783L84.7572 44.5523C84.9133 44.7085 85.001 44.9204 85.001 45.1413V86.1665C85.001 88.5597 83.0609 90.4998 80.6677 90.4998H39.6207C39.3089 90.533 38.9966 90.531 38.685 90.494C36.4109 90.225 34.7818 88.1715 35.025 85.9013V33.8855C35.025 32.488 35.3212 31.2923 36.1515 30.4614ZM37.3304 31.6395C36.953 32.0172 36.6917 32.6805 36.6917 33.8855V85.9483C36.6917 85.9813 36.6897 86.0144 36.6858 86.0472C36.521 87.425 37.5061 88.6762 38.8808 88.8389C39.0792 88.8625 39.2773 88.8625 39.4757 88.8389C39.5083 88.8351 39.5412 88.8331 39.5741 88.8331H80.6677C82.1405 88.8331 83.3344 87.6392 83.3344 86.1665V45.4863L68.8609 31.0007H39.5741C38.3702 31.0007 37.7077 31.262 37.3304 31.6395Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.0692 29.334C69.5295 29.334 69.9026 29.7071 69.9026 30.1673V42.244C69.9026 42.2769 69.9006 42.3097 69.8967 42.3423C69.879 42.4916 69.8791 42.6417 69.8966 42.7876L69.8968 42.7892C70.0189 43.8231 70.9574 44.5614 71.9895 44.4379C72.0223 44.434 72.0554 44.432 72.0885 44.432H84.1676C84.6278 44.432 85.0009 44.8051 85.0009 45.2653C85.0009 45.7256 84.6278 46.0987 84.1676 46.0987H72.1349C70.2091 46.3005 68.4702 44.9166 68.2417 42.9854C68.2105 42.7246 68.2087 42.46 68.2359 42.198V30.1673C68.2359 29.7071 68.609 29.334 69.0692 29.334Z"
      fill="#36485C"
    />
    <path
      d="M47.3838 70.9142C47.3838 68.7989 49.0987 67.084 51.214 67.084H58.2653V80.4527H47.3838V70.9142Z"
      fill="#58728C"
    />
    <path
      d="M45.7666 60.1655C45.7666 59.7856 46.0746 59.4775 46.4546 59.4775H56.1587C56.5387 59.4775 56.8467 59.7856 56.8467 60.1655V61.797C56.8467 62.177 56.5387 62.485 56.1587 62.485H46.4546C46.0746 62.485 45.7666 62.177 45.7666 61.797V60.1655Z"
      fill="white"
    />
    <path d="M55.1553 67.084H58.2643V80.6082H55.1553V67.084Z" fill="white" fillOpacity={0.35} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7571 60.5886C44.7571 59.4232 45.7018 58.4785 46.8671 58.4785H55.8018C56.9671 58.4785 57.9118 59.4232 57.9118 60.5886V61.4048C57.9118 62.2611 57.3961 62.997 56.6583 63.3187V63.8768C57.2912 64.1986 57.8403 64.6691 58.256 65.2516C58.7903 66.0003 59.0776 66.8967 59.0777 67.8162V79.2895C59.0777 80.4548 58.1329 81.3996 56.9676 81.3996H45.7771C44.6117 81.3996 43.667 80.4548 43.667 79.2895V67.8146C43.6672 66.9121 43.9441 66.0313 44.4603 65.2907C44.8624 64.7138 45.3947 64.2434 46.0106 63.9152V63.3187C45.2728 62.997 44.7571 62.2611 44.7571 61.4048V60.5886ZM47.6773 62.6584V64.4523C47.6773 64.8008 47.4604 65.1125 47.1337 65.2336C46.6049 65.4297 46.1492 65.7823 45.8276 66.2437C45.5061 66.705 45.3338 67.2532 45.3337 67.8146C45.3337 67.8145 45.3337 67.8146 45.3337 67.8146V79.2895C45.3337 79.5344 45.5322 79.7329 45.7771 79.7329H56.9676C57.2125 79.7329 57.411 79.5344 57.411 79.2895V67.8162C57.411 67.8162 57.411 67.8162 57.411 67.8162C57.411 67.2442 57.2322 66.6862 56.8994 66.2197C56.5665 65.7533 56.0959 65.4015 55.5527 65.2138C55.2169 65.0978 54.9916 64.7815 54.9916 64.4262V62.6584C54.9916 62.1981 55.3647 61.825 55.8249 61.825C56.057 61.825 56.2452 61.6369 56.2452 61.4048V60.5886C56.2452 60.3437 56.0466 60.1452 55.8018 60.1452H46.8671C46.6222 60.1452 46.4237 60.3437 46.4237 60.5886V61.4048C46.4237 61.6369 46.6119 61.825 46.844 61.825C47.3042 61.825 47.6773 62.1981 47.6773 62.6584Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.1572 62.6497C46.1572 62.1895 46.5303 61.8164 46.9906 61.8164L55.8377 61.8164C56.2979 61.8164 56.671 62.1895 56.671 62.6497C56.671 63.11 56.2979 63.4831 55.8377 63.4831L46.9906 63.4831C46.5303 63.4831 46.1572 63.11 46.1572 62.6497Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.667 39.8333C43.667 39.3731 44.0401 39 44.5003 39H50.167C50.6272 39 51.0003 39.3731 51.0003 39.8333C51.0003 40.2936 50.6272 40.6667 50.167 40.6667H44.5003C44.0401 40.6667 43.667 40.2936 43.667 39.8333Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.667 46.8333C43.667 46.3731 44.0401 46 44.5003 46H63.0003C63.4606 46 63.8337 46.3731 63.8337 46.8333C63.8337 47.2936 63.4606 47.6667 63.0003 47.6667H44.5003C44.0401 47.6667 43.667 47.2936 43.667 46.8333Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5 59.3333C64.5 58.8731 64.8731 58.5 65.3333 58.5L76.8333 58.5C77.2936 58.5 77.6667 58.8731 77.6667 59.3333C77.6667 59.7936 77.2936 60.1667 76.8333 60.1667L65.3333 60.1667C64.8731 60.1667 64.5 59.7936 64.5 59.3333Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5 80.3333C64.5 79.8731 64.8731 79.5 65.3333 79.5L76.8333 79.5C77.2936 79.5 77.6667 79.8731 77.6667 80.3333C77.6667 80.7936 77.2936 81.1667 76.8333 81.1667L65.3333 81.1667C64.8731 81.1667 64.5 80.7936 64.5 80.3333Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5 73.3333C64.5 72.8731 64.8731 72.5 65.3333 72.5L76.8333 72.5C77.2936 72.5 77.6667 72.8731 77.6667 73.3333C77.6667 73.7936 77.2936 74.1667 76.8333 74.1667L65.3333 74.1667C64.8731 74.1667 64.5 73.7936 64.5 73.3333Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5 66.3333C64.5 65.8731 64.8731 65.5 65.3333 65.5L76.8333 65.5C77.2936 65.5 77.6667 65.8731 77.6667 66.3333C77.6667 66.7936 77.2936 67.1667 76.8333 67.1667L65.3333 67.1667C64.8731 67.1667 64.5 66.7936 64.5 66.3333Z"
      fill="#36485C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.667 52.1673C43.667 51.7071 44.0401 51.334 44.5003 51.334H76.8337C77.2939 51.334 77.667 51.7071 77.667 52.1673C77.667 52.6276 77.2939 53.0007 76.8337 53.0007H44.5003C44.0401 53.0007 43.667 52.6276 43.667 52.1673Z"
      fill="#36485C"
    />
  </svg>
);
export default SvgPrescriptionDoc;
