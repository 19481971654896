const SvgMountains = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <mask
      id="mask0_485_4256"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={0}
      width={112}
      height={120}
    >
      <path
        d="M115.776 63.9856C115.776 94.7091 90.829 119.615 60.0551 119.615C29.2812 119.615 4.33398 94.7091 4.33398 63.9856C4.33398 36.1958 26.1414 0.5 59.7811 0.5C67.687 0.5 74.4878 0.499938 76.4974 1.68749C96.4603 12.4248 115.776 41.0608 115.776 63.9856Z"
        fill="#C6365B"
      />
    </mask>
    <g mask="url(#mask0_485_4256)">
      <path
        d="M39.9941 48.4122L52.3488 28.1152L64.7035 48.4122L56.3199 45.7648L52.3488 48.4122L47.0539 45.7648L39.9941 48.4122Z"
        fill="#C0D8F0"
      />
      <path
        d="M73.1796 64.2858L82.6199 46.1941L92.0602 64.6345H87.6478L82.6199 68.3319L77.3141 64.6345L73.1796 64.2858Z"
        fill="#C0D8F0"
      />
      <path
        d="M72.2035 6.27441H52.7891V21.7177H74.4097L65.585 13.3342L72.2035 6.27441Z"
        fill="#FEEEF1"
      />
      <path
        d="M73.3529 63.8561L52.238 27.0127L10.6514 98.4932H109.268L82.5731 45.9859L61.1731 87.4623"
        stroke="#36485C"
        strokeWidth={1.82692}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.7734 48.743L47.0539 45.7646L52.3487 48.743L56.9817 45.7646L64.7034 48.743M72.8663 64.7379H77.389L82.4632 68.3781L87.3168 64.7379H92.0601"
        stroke="#36485C"
        strokeWidth={1.82692}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.3486 27.0126V22.1589M52.3486 22.1589V6.16406H73.3074L65.3651 13.996L73.3074 22.1589H52.3486Z"
        stroke="#36485C"
        strokeWidth={1.82692}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x={50.8037} y={54.1484} width={2.86805} height={2.86805} rx={0.365385} fill="#36485C" />
      <rect x={50.8037} y={59.8848} width={2.86805} height={2.86805} rx={0.365385} fill="#36485C" />
      <rect x={50.8037} y={65.6211} width={2.86805} height={2.86805} rx={0.365385} fill="#36485C" />
    </g>
  </svg>
);
export default SvgMountains;
