const SvgLighthouse = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 -1.32224e-05 93.1371 -1.32224e-05 60C-1.32224e-05 26.8629 26.8629 6.15765e-06 60 6.15765e-06C93.1371 6.15765e-06 120 26.8629 120 60Z"
      fill="#F6F9FC"
    />
    <rect
      x={77.167}
      y={40.4395}
      width={20.5}
      height={2.71041}
      rx={1.3552}
      transform="rotate(-6.36357 77.167 40.4395)"
      fill="#FCE7B1"
    />
    <rect
      width={20.5}
      height={2.71041}
      rx={1.3552}
      transform="matrix(-0.993839 -0.110837 -0.110837 0.993839 42.6074 40.376)"
      fill="#FCE7B1"
    />
    <rect
      x={99.667}
      y={37.8955}
      width={4.50057}
      height={2.71041}
      rx={1.3552}
      transform="rotate(-6.36357 99.667 37.8955)"
      fill="#FCE7B1"
    />
    <rect
      width={4.50057}
      height={2.71041}
      rx={1.3552}
      transform="matrix(-0.993839 -0.110837 -0.110837 0.993839 20.1074 37.832)"
      fill="#FCE7B1"
    />
    <rect
      x={77.4414}
      y={48.334}
      width={14.9089}
      height={2.60207}
      rx={1.30104}
      transform="rotate(6.04977 77.4414 48.334)"
      fill="#FCE7B1"
    />
    <rect
      width={14.9089}
      height={2.60207}
      rx={1.30104}
      transform="matrix(-0.994431 0.105392 0.105392 0.994431 42.333 48.2705)"
      fill="#FCE7B1"
    />
    <rect
      x={95.1074}
      y={50.334}
      width={8.72364}
      height={2.60207}
      rx={1.30104}
      transform="rotate(6.04977 95.1074 50.334)"
      fill="#FCE7B1"
    />
    <rect
      width={8.72364}
      height={2.60207}
      rx={1.30104}
      transform="matrix(-0.994431 0.105392 0.105392 0.994431 24.666 50.2705)"
      fill="#FCE7B1"
    />
    <path
      d="M69.8333 40.6667C69.8333 38.1029 68.8149 35.6442 67.002 33.8313C65.1892 32.0184 62.7304 31 60.1667 31C57.6029 31 55.1442 32.0184 53.3313 33.8313C51.5185 35.6442 50.5 38.1029 50.5 40.6667L60.1667 40.6667H69.8333Z"
      fill="#C5CFD9"
    />
    <path
      d="M64 101.167C64 100.642 63.8965 95.7886 63.6955 95.3033C63.4945 94.8179 63.1999 94.377 62.8284 94.0056C62.457 93.6341 62.016 93.3395 61.5307 93.1385C61.0454 92.9374 60.5253 92.834 60 92.834C59.4747 92.834 58.9546 92.9374 58.4693 93.1385C57.984 93.3395 57.543 93.6341 57.1716 94.0056C56.8001 94.377 56.5055 94.8179 56.3045 95.3033C56.1035 95.7886 56 100.642 56 101.167H60H64Z"
      fill="#C5CFD9"
    />
    <path d="M50.6673 54.084L46.584 101.167H60.4173V54.084H50.6673Z" fill="#C5CFD9" />
    <mask
      id="mask0_485_4272"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={120}
      height={120}
    >
      <path
        d="M119.945 59.9723C119.945 93.0019 93.1689 119.778 60.1393 119.778C27.1098 119.778 0.333984 93.0019 0.333984 59.9723C0.333984 26.9428 27.1098 0.166992 60.1393 0.166992C93.1689 0.166992 119.945 26.9428 119.945 59.9723Z"
        fill="#F6F9FC"
      />
    </mask>
    <g mask="url(#mask0_485_4272)">
      <path
        d="M50.6562 40.6207C50.6562 35.4155 54.8784 31.1934 60.0836 31.1934C65.2889 31.1934 69.511 35.4155 69.511 40.6207"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.084 28.5V31.1935"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9629 40.6211H72.2047"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6562 40.6211V48.7017"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.084 40.6211V48.7017"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5107 40.6211V48.7017"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.2047 48.7012H47.9629"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9629 54.0889H72.2047"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6565 54.0889L46.6162 101.226"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5107 54.0889L73.551 101.226"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2295 106.613V101.226H78.9389V106.613"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6562 109.306C50.6562 109.306 53.3498 111.999 60.0836 111.999C66.8174 111.999 69.511 109.306 69.511 109.306"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5107 109.306C69.5107 109.306 72.2009 111.999 77.5913 111.999C82.9818 111.999 85.6719 109.306 85.6719 109.306"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.6719 109.306C85.6719 109.306 88.362 111.999 93.7525 111.999C99.1429 111.999 101.833 109.306 101.833 109.306"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4951 109.306C34.4951 109.306 37.1853 111.999 42.5757 111.999C47.9661 111.999 50.6563 109.306 50.6563 109.306"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.334 109.306C18.334 109.306 21.0241 111.999 26.4146 111.999C31.805 111.999 34.4952 109.306 34.4952 109.306"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.0439 101.225V97.1848C56.0439 94.9526 57.852 93.1445 60.0842 93.1445C62.3165 93.1445 64.1245 94.9526 64.1245 97.1848V101.225"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9629 86.4111H66.8176"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.04 75.6367H65.4706"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.8486 64.8623H64.1244"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.438 38.0826L101.835 37.9277"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5918 40.6209L95.1132 38.6748"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.0215 50.6377L101.833 51.3953"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5918 48.7012L89.7127 50.0479"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7313 38.0826L18.334 37.9277"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5761 40.6209L25.0547 38.6748"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1453 50.6377L18.334 51.3953"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.576 48.7012L30.4551 50.0479"
        stroke="#36485C"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default SvgLighthouse;
