const SvgEnvelope = props => (
  <svg viewBox="0 0 612 624" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={306} cy={306} r={306} fill="#E4E9ED" />
    <mask
      id="mask0_1507_2839"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={12}
      width={612}
      height={612}
    >
      <path
        d="M611.999 317.952C611.999 486.799 475.104 623.676 306.237 623.676C137.37 623.676 0.476562 486.799 0.476562 317.952C0.476562 174.55 114.538 12.2285 306.237 12.2285C471.038 12.2285 611.999 149.106 611.999 317.952Z"
        fill="#C6365B"
      />
    </mask>
    <g mask="url(#mask0_1507_2839)">
      <rect x={152.597} y={137.439} width={308.497} height={316.34} rx={4} fill="white" />
      <path
        d="M105.538 480.28V336.119C105.538 333.242 108.485 331.306 111.126 332.448L308.459 417.795C309.494 418.242 310.669 418.232 311.696 417.767L499.889 332.588C502.536 331.39 505.538 333.326 505.538 336.232V480.28C505.538 482.489 503.747 484.28 501.538 484.28H109.538C107.329 484.28 105.538 482.489 105.538 480.28Z"
        fill="#58728C"
      />
      <path
        d="M151.694 350.064V150.014C151.694 146.615 153.045 143.354 155.449 140.95C157.853 138.546 161.114 137.195 164.513 137.195H446.532C449.932 137.195 453.193 138.546 455.597 140.95C458.001 143.354 459.351 146.615 459.351 150.014V350.064"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.828 329.48L288.778 417.515C293.999 420.04 299.724 421.352 305.523 421.352C311.323 421.352 317.047 420.04 322.268 417.515L504.218 329.48"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.695 180.461L107.085 207.701H106.828V464.082C106.828 470.882 109.529 477.403 114.337 482.211C119.145 487.019 125.667 489.72 132.466 489.72H478.58C485.38 489.72 491.901 487.019 496.709 482.211C501.517 477.403 504.218 470.882 504.218 464.082V207.701H503.962L459.352 180.461"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.366 137.196L285.461 97.9854C291.505 94.2859 298.453 92.3281 305.539 92.3281C312.625 92.3281 319.573 94.2859 325.617 97.9854L389.712 137.196"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.608 201.291H305.523"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.608 252.566H382.437"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.608 303.844H382.437"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default SvgEnvelope;
