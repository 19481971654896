const SvgPaperPlane = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <path
      d="M89.1398 35.6721C89.2931 35.5852 89.4076 35.4433 89.4603 35.2751C89.5129 35.1069 89.4997 34.925 89.4234 34.7662C89.3471 34.6074 89.2133 34.4834 89.0491 34.4195C88.8849 34.3555 88.7025 34.3563 88.5389 34.4217L33.0032 57.7845C32.7765 57.8807 32.5798 58.0359 32.4335 58.234C32.2872 58.432 32.1967 58.6657 32.1714 58.9106C32.146 59.1556 32.1869 59.4028 32.2895 59.6266C32.3922 59.8504 32.5531 60.0425 32.7552 60.1831L39.7811 64.0719L89.1398 35.6721Z"
      fill="white"
    />
    <path d="M45.6621 67.6124L78.7646 44.8613L47.7911 72.7733L45.6621 67.6124Z" fill="white" />
    <path d="M53.4209 83.1879L54.825 72.3486L60.6192 76.0839L53.4209 83.1879Z" fill="white" />
    <path
      d="M85.5703 35.0008L30.7354 57.7843C30.5313 57.8695 30.3549 58.0096 30.2257 58.1891C30.0964 58.3685 30.0194 58.5802 30.0032 58.8008C29.987 59.0213 30.0323 59.242 30.1339 59.4384C30.2356 59.6348 30.3897 59.7992 30.5791 59.9133L43.3964 67.6129C43.6331 67.7553 43.8131 67.9753 43.9058 68.2354L49.1017 82.8315C49.1731 83.0307 49.2961 83.2073 49.458 83.3435C49.62 83.4797 49.8151 83.5704 50.0236 83.6066C50.232 83.6428 50.4464 83.623 50.6447 83.5493C50.843 83.4757 51.0183 83.3507 51.1526 83.1872L56.7797 76.3312C56.9657 76.1052 57.2279 75.9552 57.5169 75.9096C57.806 75.8639 58.1017 75.9257 58.3482 76.0833L73.4213 85.7286C73.5814 85.8315 73.7637 85.8947 73.9531 85.9128C74.1426 85.9309 74.3336 85.9035 74.5103 85.8328C74.6869 85.7622 74.8442 85.6503 74.9688 85.5065C75.0935 85.3627 75.182 85.1913 75.227 85.0064L87.1873 36.3914C87.2396 36.177 87.2317 35.9522 87.1642 35.742C87.0967 35.5319 86.9725 35.3444 86.8051 35.2005C86.6377 35.0565 86.4338 34.9617 86.2159 34.9265C85.9979 34.8912 85.7745 34.917 85.5703 35.0008V35.0008Z"
      stroke="#36485C"
      strokeWidth={1.89458}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.5567 72.3473L76.499 44.8584L43.3965 67.6122"
      stroke="#36485C"
      strokeWidth={1.89458}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.5564 72.3486L51.1523 83.1879L58.3507 76.0839L52.5564 72.3486Z"
      stroke="#36485C"
      strokeWidth={1.89458}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPaperPlane;
