const SvgPrescriptionLaptop = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={60} cy={60} r={60} fill="#F6F9FC" />
    <rect x={21.5186} y={77.7256} width={76.8528} height={12.1081} rx={6.05405} fill="#EBF0F5" />
    <rect
      opacity={0.75}
      x={32.1133}
      y={34.9307}
      width={60.3723}
      height={34.6426}
      rx={2.25667}
      fill="#60768F"
    />
    <path
      d="M40.5215 34.9316H34.3698C33.1234 34.9316 32.1131 35.942 32.1131 37.1883V67.3176C32.1131 68.5639 33.1234 69.5743 34.3698 69.5743H40.5215V34.9316Z"
      fill="#60768F"
    />
    <rect
      x={27.5648}
      y={34.6673}
      width={64.9279}
      height={39.5345}
      rx={3.68002}
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <path
      d="M28.6469 74.0189H91.412C92.7732 74.0189 94.0232 74.7702 94.6618 75.9723L96.299 79.0537C97.6013 81.5047 95.8247 84.4603 93.0492 84.4603H27.0098C24.2342 84.4603 22.4577 81.5047 23.76 79.0537L25.3971 75.9723C26.0358 74.7702 27.2858 74.0189 28.6469 74.0189Z"
      fill="white"
      stroke="#36485C"
      strokeWidth={1.66667}
    />
    <path d="M27.834 83.6673L31.7626 74.834H40.1673L37.0699 83.6673H27.834Z" fill="#C5CFD9" />
    <path
      d="M40.9121 42.9245C40.9121 42.5563 41.2106 42.2578 41.5788 42.2578H78.4992C78.8674 42.2578 79.1659 42.5563 79.1659 42.9245V72.5171C79.1659 72.8853 78.8674 73.1837 78.4992 73.1837H41.5788C41.2106 73.1837 40.9121 72.8853 40.9121 72.5171V42.9245Z"
      fill="white"
    />
    <path
      d="M40.9121 73.1674V43.8499C40.9121 43.4277 41.0799 43.0227 41.3784 42.7241C41.677 42.4256 42.082 42.2578 42.5042 42.2578H77.5311C77.9534 42.2578 78.3583 42.4256 78.6569 42.7241C78.9555 43.0227 79.1233 43.4277 79.1233 43.8499V73.1674"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.667 51.668H66.3337"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.667 58.668H72.0003"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.667 64.001H72.0003"
      stroke="#36485C"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.8154 57.8659C48.8154 56.6693 49.7855 55.6992 50.9821 55.6992H56.4445V65.9895H48.8154V57.8659Z"
      fill="#86B09B"
    />
    <rect x={53.4277} y={55.6992} width={2.66129} height={10.4677} fill="#B6CEC2" />
    <path
      d="M54.3469 53.6128V52.2489H54.9241C55.2002 52.2489 55.4241 52.025 55.4241 51.7489V50.167C55.4241 49.8909 55.2002 49.667 54.9241 49.667H47.8272C47.5511 49.667 47.3272 49.8908 47.3272 50.167V51.7489C47.3272 52.025 47.5511 52.2489 47.8272 52.2489H48.4044V53.6329C47.8133 53.8297 47.3036 54.1837 46.9437 54.6474C46.5837 55.111 46.3907 55.6622 46.3906 56.2269V64.565C46.3906 65.3934 47.0622 66.065 47.8906 66.065H54.9258C55.7542 66.065 56.4258 65.3934 56.4258 64.565V56.2282C56.4257 55.6528 56.2255 55.0918 55.853 54.623C55.4804 54.1543 54.954 53.8012 54.3469 53.6128Z"
      stroke="#36485C"
      strokeWidth={1.16667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.5323 52.333H48.5"
      stroke="#36485C"
      strokeWidth={1.16667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPrescriptionLaptop;
