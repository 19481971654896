const SvgRocket = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2206_479)">
      <circle cx={60} cy={60} r={60} fill="#EBF2EF" />
      <mask
        id="mask0_2206_479"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={120}
        height={120}
      >
        <path
          d="M119.998 59.9458C119.998 93.053 93.1996 119.892 60.1424 119.892C27.0852 119.892 0.287109 93.053 0.287109 59.9458C0.287109 31.8277 22.6156 0 60.1424 0C76.1368 0 94.5077 2.2542 105.414 12.9412C116.506 23.8102 119.998 43.2525 119.998 59.9458Z"
          fill="#C6365B"
        />
      </mask>
      <g mask="url(#mask0_2206_479)">
        <path
          d="M58.1157 75.0737L51.4453 68.2069L103.974 15.1279C103.604 40.1455 73.2475 65.5158 58.1157 75.0737Z"
          fill="#86B09B"
        />
        <path
          d="M57.7847 75.9642C57.6365 75.9651 57.4896 75.9366 57.3524 75.8805C57.2152 75.8244 57.0904 75.7417 56.9851 75.6371L43.4707 62.1006C43.2927 61.9206 43.1804 61.6859 43.1518 61.4342C43.1232 61.1825 43.18 60.9285 43.313 60.7131C48.6186 52.3831 54.7011 44.5759 61.4787 37.3965C76.063 22.0438 90.7487 13.9219 103.959 13.9219C104.258 13.9219 104.544 14.0407 104.755 14.2523C104.967 14.4638 105.085 14.7507 105.085 15.0499C105.085 28.2819 96.9766 42.9915 81.649 57.5997C74.4813 64.3884 66.6868 70.4808 58.3704 75.795C58.1944 75.9043 57.9918 75.9628 57.7847 75.9642ZM45.7006 61.1418L57.9424 73.4036C63.7874 69.6472 101.785 44.1534 102.833 16.178C74.903 17.227 49.4508 55.2872 45.7006 61.1418Z"
          fill="#36485C"
        />
        <path
          d="M52.158 77.0913C52.0098 77.0922 51.8628 77.0637 51.7256 77.0076C51.5884 76.9515 51.4636 76.8687 51.3584 76.7642L42.3488 67.7399C42.2432 67.635 42.1594 67.5102 42.1022 67.3728C42.0451 67.2353 42.0156 67.0879 42.0156 66.9389C42.0156 66.79 42.0451 66.6426 42.1022 66.5051C42.1594 66.3677 42.2432 66.2429 42.3488 66.138L45.7274 62.7539L47.3266 64.3557L44.7363 66.9389L52.158 74.3727L54.737 71.7782L56.3362 73.3801L52.9576 76.7642C52.8524 76.8687 52.7276 76.9515 52.5903 77.0076C52.4531 77.0637 52.3062 77.0922 52.158 77.0913Z"
          fill="#36485C"
        />
        <path
          d="M34.136 86.116C33.8373 86.116 33.5508 85.9971 33.3396 85.7856C33.1284 85.574 33.0098 85.2871 33.0098 84.9879C33.3645 79.1287 35.8908 73.6134 40.0936 69.5225L41.6928 71.1243C38.2691 74.5353 36.0474 78.9702 35.3635 83.7584C40.1438 83.0734 44.5715 80.848 47.977 77.4188L49.5762 79.0206C45.492 83.2302 39.9856 85.7606 34.136 86.116Z"
          fill="#36485C"
        />
        <ellipse cx={74.6991} cy={44.3594} rx={4.26162} ry={4.26858} fill="white" />
        <ellipse cx={84.8905} cy={34.1514} rx={4.26162} ry={4.26858} fill="white" />
        <ellipse cx={64.5097} cy={54.5664} rx={4.26162} ry={4.26858} fill="white" />
        <path
          d="M84.8146 39.8664C83.7009 39.8664 82.6122 39.5356 81.6862 38.9158C80.7602 38.2961 80.0384 37.4152 79.6122 36.3846C79.186 35.354 79.0745 34.2199 79.2918 33.1258C79.5091 32.0317 80.0454 31.0267 80.8329 30.2379C81.6204 29.4491 82.6237 28.9119 83.7161 28.6943C84.8084 28.4767 85.9406 28.5884 86.9695 29.0153C87.9984 29.4422 88.8779 30.1651 89.4966 31.0926C90.1154 32.0201 90.4456 33.1106 90.4456 34.2262C90.4456 34.9668 90.3 35.7003 90.017 36.3846C89.734 37.0689 89.3192 37.6906 88.7963 38.2144C88.2734 38.7381 87.6527 39.1536 86.9695 39.437C86.2863 39.7205 85.5541 39.8664 84.8146 39.8664ZM84.8146 30.842C84.1464 30.842 83.4932 31.0405 82.9376 31.4124C82.3819 31.7842 81.9489 32.3127 81.6932 32.9311C81.4375 33.5495 81.3706 34.2299 81.5009 34.8864C81.6313 35.5428 81.9531 36.1458 82.4256 36.6191C82.8981 37.0924 83.5001 37.4147 84.1555 37.5453C84.8109 37.6758 85.4902 37.6088 86.1075 37.3527C86.7249 37.0965 87.2526 36.6628 87.6238 36.1063C87.9951 35.5498 88.1932 34.8955 88.1932 34.2262C88.1932 33.3286 87.8373 32.4679 87.2036 31.8332C86.57 31.1986 85.7107 30.842 84.8146 30.842Z"
          fill="#36485C"
        />
        <path
          d="M74.6779 50.0187C73.5642 50.0187 72.4755 49.6879 71.5495 49.0682C70.6235 48.4484 69.9017 47.5675 69.4755 46.5369C69.0493 45.5063 68.9378 44.3722 69.1551 43.2781C69.3723 42.1841 69.9087 41.1791 70.6962 40.3903C71.4837 39.6015 72.487 39.0643 73.5793 38.8467C74.6716 38.629 75.8039 38.7407 76.8328 39.1676C77.8617 39.5945 78.7412 40.3174 79.3599 41.245C79.9787 42.1725 80.3089 43.263 80.3089 44.3785C80.3089 45.8744 79.7156 47.309 78.6596 48.3667C77.6036 49.4245 76.1713 50.0187 74.6779 50.0187ZM74.6779 40.9944C74.0097 40.9944 73.3564 41.1928 72.8008 41.5647C72.2452 41.9366 71.8122 42.4651 71.5565 43.0835C71.3007 43.7018 71.2338 44.3823 71.3642 45.0387C71.4946 45.6952 71.8163 46.2982 72.2889 46.7714C72.7614 47.2447 73.3634 47.567 74.0188 47.6976C74.6741 47.8282 75.3535 47.7612 75.9708 47.505C76.5882 47.2489 77.1159 46.8151 77.4871 46.2586C77.8583 45.7021 78.0565 45.0478 78.0565 44.3785C78.0565 43.481 77.7005 42.6202 77.0669 41.9856C76.4333 41.3509 75.574 40.9944 74.6779 40.9944Z"
          fill="#36485C"
        />
        <path
          d="M64.5431 60.1711C63.4294 60.1711 62.3407 59.8403 61.4147 59.2205C60.4887 58.6008 59.7669 57.7199 59.3407 56.6893C58.9145 55.6586 58.803 54.5246 59.0203 53.4305C59.2376 52.3364 59.7739 51.3314 60.5614 50.5426C61.3489 49.7538 62.3523 49.2166 63.4446 48.999C64.5369 48.7814 65.6691 48.8931 66.698 49.32C67.727 49.7469 68.6064 50.4698 69.2251 51.3973C69.8439 52.3248 70.1741 53.4153 70.1741 54.5308C70.1741 55.2715 70.0285 56.005 69.7455 56.6893C69.4625 57.3736 69.0477 57.9953 68.5249 58.5191C68.002 59.0428 67.3812 59.4583 66.698 59.7417C66.0148 60.0252 65.2826 60.1711 64.5431 60.1711ZM64.5431 51.1467C63.8749 51.1467 63.2217 51.3452 62.6661 51.717C62.1105 52.0889 61.6774 52.6174 61.4217 53.2358C61.166 53.8542 61.0991 54.5346 61.2294 55.1911C61.3598 55.8475 61.6816 56.4505 62.1541 56.9238C62.6266 57.3971 63.2286 57.7194 63.884 57.8499C64.5394 57.9805 65.2187 57.9135 65.8361 57.6574C66.4534 57.4012 66.9811 56.9675 67.3523 56.411C67.7236 55.8544 67.9217 55.2002 67.9217 54.5308C67.9217 53.6333 67.5658 52.7726 66.9322 52.1379C66.2986 51.5033 65.4392 51.1467 64.5431 51.1467Z"
          fill="#36485C"
        />
        <path
          d="M74.6792 86.1163H63.4172C63.1185 86.1163 62.8321 85.9975 62.6209 85.7859C62.4097 85.5744 62.291 85.2875 62.291 84.9883V75.964H64.5434V83.8603H73.9472L92.7999 41.6602L94.8495 42.5852L75.7041 85.4508C75.6153 85.6487 75.4713 85.8168 75.2896 85.9348C75.1078 86.0529 74.8959 86.1159 74.6792 86.1163Z"
          fill="#36485C"
        />
        <path
          d="M43.1456 56.7875H34.136C33.8373 56.7875 33.5508 56.6687 33.3396 56.4571C33.1284 56.2456 33.0098 55.9586 33.0098 55.6595V44.379C33.0102 44.162 33.0731 43.9498 33.191 43.7677C33.3089 43.5856 33.4766 43.4415 33.6742 43.3525L76.4699 24.1758L77.3934 26.2288L35.2622 45.1123V54.5314H43.1456V56.7875Z"
          fill="#36485C"
        />
        <path
          d="M101.708 27.459C99.0199 27.459 96.4418 26.3894 94.541 24.4855C92.6401 22.5815 91.5723 19.9992 91.5723 17.3066H93.8247C93.8247 19.4009 94.6552 21.4093 96.1337 22.8902C97.6121 24.371 99.6173 25.2029 101.708 25.2029V27.459Z"
          fill="#36485C"
        />
        <path
          d="M35.5104 67.5625C15.4987 76.8249 -6.3601 72.1169 -13.3125 57.0469"
          stroke="#36485C"
          strokeWidth={1.95759}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.7896 82.9613C35.1759 97.4753 30.5394 119.383 41.4336 131.895"
          stroke="#36485C"
          strokeWidth={1.95759}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <ellipse
        cx={15.2095}
        cy={98.3513}
        rx={1.87752}
        ry={1.88058}
        stroke="#307553"
        strokeWidth={0.978793}
      />
      <ellipse
        cx={7.80909}
        cy={111.723}
        rx={3.28565}
        ry={3.29102}
        stroke="#307553"
        strokeWidth={0.978793}
      />
      <ellipse
        cx={24.2154}
        cy={107.176}
        rx={1.87752}
        ry={1.88058}
        stroke="#86B09B"
        strokeWidth={0.978793}
      />
      <ellipse
        cx={2.87752}
        cy={97.9597}
        rx={1.87752}
        ry={1.88058}
        stroke="#86B09B"
        strokeWidth={0.978793}
      />
      <ellipse
        cx={26.2564}
        cy={91.3916}
        rx={3.28565}
        ry={3.29102}
        stroke="#86B09B"
        strokeWidth={0.978793}
      />
    </g>
    <defs>
      <clipPath id="clip0_2206_479">
        <rect width={120} height={120} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRocket;
