const SvgNoSwaps = props => (
  <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_485_4051)">
      <circle cx={60} cy={60} r={60} fill="#EBF2EF" />
      <mask
        id="mask0_485_4051"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={120}
        height={120}
      >
        <path
          d="M120 57.3294C120 87.2248 88.12 120 60.0887 120C32.0573 120 0 87.2248 0 57.3294C0 30.2886 29.1973 0 59.8391 0C67.0404 0 73.2352 -6.0107e-05 75.0657 1.15548C93.2495 11.6034 120 35.0224 120 57.3294Z"
          fill="#C6365B"
        />
      </mask>
      <g mask="url(#mask0_485_4051)">
        <g clipPath="url(#clip1_485_4051)">
          <path
            d="M60.8543 94.5234H40.7871L42.314 121.688H59.4365L60.1454 108.106L60.8543 94.5234Z"
            fill="white"
          />
          <path
            opacity={0.35}
            d="M79.3278 69.3745C71.1618 75.2569 63.4756 71.6283 60.6533 69.0788C62.414 66.3585 79.3057 57.2077 87.5315 52.9723C89.4508 61.9344 82.862 67.6413 79.3278 69.3745Z"
            fill="#86B09B"
          />
          <path
            d="M40.5369 77.3309C31.5307 72.8393 31.1666 64.3475 32.1103 60.6631C35.3374 60.956 51.2964 71.6508 58.8726 76.9615C51.8325 82.83 43.7154 79.653 40.5369 77.3309Z"
            fill="#86B09B"
          />
          <path
            d="M59.9507 42.4022C61.3083 35.6478 60.1342 18.8216 59.3774 11.2528C52.8096 12.9917 50.182 22.0647 49.6892 26.3838C49.055 35.3021 56.266 40.7787 59.9507 42.4022Z"
            fill="#86B09B"
          />
          <path
            opacity={0.35}
            d="M53.5428 95.248H40.877L43.6677 121.653H53.5428V95.248Z"
            fill="#86B09B"
          />
          <path
            d="M38.124 94.533V89.707H82.0565V94.533H38.124Z"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.6211 94.5332L43.2005 122.157H77.1483L79.7277 94.5332"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.6214 27.2991C49.0802 18.6349 55.8595 12.7084 59.3168 10.8281C68.5161 15.8822 69.658 24.1403 69.658 27.2991C68.5758 37.4074 63.225 41.7395 60.4442 42.642C51.6056 39.7539 49.5462 31.2101 49.6214 27.2991Z"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M68.9707 52.2398C76.2477 47.5065 84.7445 50.4977 88.0832 52.585C88.2111 63.0858 81.8204 68.5622 79.0702 70.116C69.7369 74.1452 63.0944 71.1947 60.9399 69.2159C59.1038 60.0948 65.5287 54.098 68.9707 52.2398Z"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.7928 60.2363C58.2153 64.738 59.2081 73.691 58.7767 77.6048C49.3719 82.2773 41.6645 78.8967 39.0711 77.0934C31.3905 70.4336 31.1658 63.1688 32.0134 60.3689C39.4339 54.7562 47.6249 57.9418 50.7928 60.2363Z"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1={40.8509}
            y1={64.9613}
            x2={57.3602}
            y2={76.7214}
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M61.7148 68.0928L78.8026 58.2272"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.1943 42.3057L60.0019 22.5754"
            stroke="#36485C"
            strokeWidth={1.66411}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M60.1143 42.6426V88.8951" stroke="#36485C" strokeWidth={1.66411} />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_485_4051">
        <rect width={120} height={120} fill="white" />
      </clipPath>
      <clipPath id="clip1_485_4051">
        <rect width={58.7431} height={123.371} fill="white" transform="translate(30.4688)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNoSwaps;
